import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FaThLarge, FaLayerGroup, FaHeadset, FaHandshake, FaSyncAlt } from 'react-icons/fa';


import reforma from './Assets/reformas.png';
import assistencia from './Assets/assitencia.png';
import aulas from './Assets/aulas.png';
import carro from './Assets/carro.png';
import consultoria from './Assets/consutoria.png';
import design from './Assets/designer-de-web.png';
import evento from './Assets/evento.png';
import moda from './Assets/moda.png';
import saude from './Assets/saude.png';
import servicos from './Assets/serviços.png';

import qualidade from './Assets/aperto-de-mao.png'
import atualizar from './Assets/atualizar.png'

import android from './Assets/android.png';
import ios from './Assets/ios.png';
import phone from './Assets/Phone Mockup.png';
import logoFull from './Assets/LOGO 02.png';
import simbolo from './Assets/SIMBOLO 03.png';

import ome from './Assets/confident-young-handsome-man-shirt-holding-laptop-smiling-while-standing-against-white-background.png';
import omefurano from './Assets/portrait-handyman.png'
import muieCelular from './Assets/cheerful-african-american-with-crisp-hair-holds-modern-smart-phone-happy-smile.png'

import miniLogo from './Assets/miniLogo.png';

import { FaShieldAlt, FaLock } from 'react-icons/fa';

import Api from './Auth/Api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Landing() {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const registerEmail = async () => {
        async function fetchData() {
            try {
                const response = await Api.post(`/preCadastro`, {
                    email: email
                });

                console.log(response);
                if (response.status === 200) {
                    toast.success('E-mail cadastrado com sucesso!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setEmail('');
                }

            } catch (error) {
                console.error(error.response.data.message)
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        fetchData();
    }

    const sendMessage = async () => {
        async function fetchData() {
            try {
                const response = await Api.post(`/contato`, {
                    name: message.name,
                    email: message.email,
                    subject: message.subject,
                    message: message.message
                });

                console.log(response);
                if (response.status === 200) {
                    setMessage({
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    });

                    toast.success('Mensagem enviada com sucesso!', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            } catch (error) {
                console.error(error.response.data.message)
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        fetchData();
    }


    return (
        <div className="font-sans bg-background">
            {/* Navbar */}
            <header className="bg-white shadow-sm">
                <div className="container d-flex justify-content-between align-items-center py-3">
                    <div className="d-flex align-items-center">
                        <img src={miniLogo} height={50} alt="Logo" className="h-10" style={{ marginLeft: 150 }} />
                        <nav className="ml-4">
                            <a href="#inicio" className="text-dark mx-3" style={{ fontSize: 20, textDecoration: 'none', marginLeft: 40 }}>Início</a>
                            <a href="#sobre" className="text-dark mx-4" style={{ fontSize: 20, textDecoration: 'none', marginLeft: 40 }}>Sobre nós</a>
                            <a href="#servicos" className="text-dark mx-4" style={{ fontSize: 20, textDecoration: 'none', marginLeft: 40 }}>Serviços</a>
                            <a href="#funcionalidades" className="text-dark mx-4" style={{ fontSize: 20, textDecoration: 'none', marginLeft: 40 }}>Funcionalidades</a>
                            <a href="#contato" className="text-dark mx-4" style={{ fontSize: 20, textDecoration: 'none', marginLeft: 40 }}>Contato</a>
                        </nav>
                    </div>
                    <div className="d-flex" style={{ marginRight: 140 }}>
                        <a href="https://www.facebook.com/profile.php?id=61560426666987" className="mx-2" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <FaFacebookF />
                        </a>
                        <a href="https://wa.me/5516997375063" className="mx-2" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <FaWhatsapp />
                        </a>
                        <a href="https://www.instagram.com/appmyhelp/" className="mx-2" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <FaInstagram />
                        </a>
                        <a href="https://www.linkedin.com/in/seuPerfil" className="mx-2" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <FaLinkedinIn />
                        </a>
                    </div>


                </div>
            </header>

            {/* Hero Section */}
            <section className="bg-primary text-white py-5" style={{ width: '100%', maxHeight: '520px' }} id='inicio'>
                <div className="container d-flex align-items-center justify-content-between" style={{marginTop: -120}}>
                    <div className="max-w-lg text-left" style={{ marginLeft: 150 }}>
                        <h1 className="display-4 mb-4">
                            Realize seu <br />
                            <span className="font-weight-bold">cadastro</span> <br />
                            no app MyHelp
                        </h1>
                        <div style={{ alignSelf: 'flex-end', marginRight: 60 }}>
                            <div className="d-flex">
                                <img src={android} alt="Google Play" className="mr-2 img-fluid" style={{ height: '55px' }} />
                                <img src={ios} alt="App Store" className="img-fluid" style={{ height: '55px' }} />
                            </div>
                        </div>
                        <div className="input-group" style={{ width: '100%', paddingBottom: 15, marginTop: 35 }}>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" placeholder="Insira seu e-mail aqui" style={{ height: 53, fontStyle: 'italic', fontWeight: 300, background: '#eee', fontSize: 18 }} />
                            <div className="input-group-append">
                                <button onClick={() => registerEmail()} className="btn btn-warning font-weight-bold position-relative" style={{ borderRadius: 7, color: 'white', right: 3, width: 140, fontWeight: 'bold', fontSize: 20, zIndex: 999 }}>Cadastrar!</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-block">
                        <img src={phone} alt="Imagem de uma pessoa sorrindo e apontando" className="img-fluid" style={{ height: 550, width: 'auto', marginTop: 120, zIndex: 99999 }} />
                    </div>
                </div>

            </section>

            {/* Services Section */}
            <section className="bg-white py-5">
                <div className="container">
                    <div className="row text-center justify-content-around">
                        <div className="col-6 col-md-1">
                            <img src={assistencia} className="fa-2x text-primary mb-2" alt="Assistência" />
                            <p className="font-weight-semibold">Assistência técnica</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={servicos} className="fa-2x text-primary mb-2" alt="Serviços domésticos" />
                            <p className="font-weight-semibold">Serviços domésticos</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={reforma} className="fa-2x text-primary mb-2" alt="Reformas e reparos" />
                            <p className="font-weight-semibold">Reformas e reparos</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={saude} className="fa-2x text-primary mb-2" alt="Saúde" />
                            <p className="font-weight-semibold">Saúde</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={moda} className="fa-2x text-primary mb-2" alt="Moda e beleza" />
                            <p className="font-weight-semibold">Moda e beleza</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={design} className="fa-2x text-primary mb-2" alt="Design e tecnologia" />
                            <p className="font-weight-semibold">Design e tecnologia</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={carro} className="fa-2x text-primary mb-2" alt="Automóvel" />
                            <p className="font-weight-semibold">Automóvel</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={consultoria} className="fa-2x text-primary mb-2" alt="Consultoria" />
                            <p className="font-weight-semibold">Consultoria</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={evento} className="fa-2x text-primary mb-2" alt="Eventos" />
                            <p className="font-weight-semibold">Eventos</p>
                        </div>
                        <div className="col-6 col-md-1">
                            <img src={aulas} className="fa-2x text-primary mb-2" alt="Aulas" />
                            <p className="font-weight-semibold">Aulas</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-strong_blue text-white py-5" id='sobre'>
                <div className="container text-center col-md-6">
                    <h2 className="display-4 font-weight-bold mb-4 mx-5 px-5">Visão & Compromisso com você</h2>
                    <p className="mb-5" style={{ fontSize: 23 }}>Dedicados à excelência e impulsionados pela inovação, buscamos transformar a maneira como você encontra e contrata serviços, oferecendo uma plataforma confiável e intuitiva que atenda às suas necessidades em constante evolução.</p>
                    <div className="row mb-5">
                        <div className="col-md-4 mb-3">
                            <div className="d-flex justify-content-center align-items-center bg-primary p-2" style={{ height: 60, borderRadius: 15, width: 280 }}>
                                <FaThLarge className="fa-2x me-2" />
                                <span className="font-weight-bold" style={{ fontSize: 20 }}>Diversas categorias</span>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="d-flex justify-content-center align-items-center bg-primary p-2" style={{ height: 60, borderRadius: 15, width: 280 }}>
                                <FaLayerGroup className="fa-2x me-3" />
                                <span className="font-weight-bold" style={{ fontSize: 20 }}>Interface intuitiva</span>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="d-flex justify-content-center align-items-center bg-primary p-2" style={{ height: 60, borderRadius: 15, width: 280 }}>
                                <FaHeadset className="fa-2x me-3" />
                                <span className="font-weight-bold" style={{ fontSize: 20 }}>Suporte ativo</span>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: 10, marginTop: 75 }}>
                        <div className="col-md-6 text-white d-flex align-items-center" style={{ borderRadius: 20 }}>
                            <div className="d-flex align-items-center justify-content-center bg-warning p-4" style={{ height: 110, width: 110, borderRadius: 20, marginRight: 20, marginBottom: 150 }}>
                                <img src={qualidade} width={90} alt="Qualidade" />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%' }}>
                                <span className="d-block text-uppercase" style={{ fontWeight: 500 }}>Plataforma</span>
                                <h5 className="mb-1" style={{ fontSize: 32, width: '50%', fontWeight: '800' }}>Qualidade Garantida</h5>
                                <p className="mb-0" style={{ fontSize: 22 }}>Nossa prioridade é oferecer serviços de alta qualidade, conectando você aos melhores profissionais em cada área.</p>
                            </div>
                        </div>
                        <div className="col-md-6 text-white d-flex align-items-center" style={{ borderRadius: 20 }}>
                            <div className="d-flex align-items-center justify-content-center bg-warning text-dark p-4" style={{ height: 110, width: 110, borderRadius: 20, marginRight: 20, marginBottom: 150 }}>
                                <img src={atualizar} width={80} alt="Qualidade" />
                            </div>
                            <div style={{ textAlign: 'start', width: '100%' }}>
                                <span className="d-block text-uppercase" style={{ fontWeight: 500 }}>Update</span>
                                <h5 className="mb-1" style={{ fontSize: 32, width: '50%', fontWeight: '800' }}>Inovação constante</h5>
                                <p className="mb-0" style={{ fontSize: 22 }}>Estamos sempre evoluindo para melhor atender você, incorporando feedback e tecnologia de ponta em nossa plataforma.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-primary text-white py-5" id='servicos'>
                <div className="container text-center" style={{ marginBottom: 80 }}>
                    <h2 className="display-4 font-weight-bold mb-4">Nossos Serviços</h2>
                    <p className="mb-5 mx-auto col-md-10" style={{ fontSize: 26 }}>
                        Oferecemos uma ampla gama de serviços para atender às suas necessidades, desde encontrar profissionais qualificados até alugar ferramentas para suas tarefas.
                        Nosso objetivo é fornecer uma plataforma abrangente que simplifique sua vida.
                    </p>
                    <div className="row col-md-10 mx-auto px-2">
                        <div className="col-md-4 mb-3">
                            <div className="bg-strong_blue text-white p-4" style={{ borderRadius: 20, width: '100%', height: 440 }}>
                                <div className="d-flex justify-content-center mb-3">
                                    <FaHeadset className="fa-5x text-warning" />
                                </div>
                                <h5 className="font-weight-bold" style={{ fontSize: 28 }}>Suporte personalizado</h5>
                                <p style={{ fontSize: 20 }}>
                                    Estamos sempre aqui para ajudar! Nosso suporte dedicado está pronto para responder às suas perguntas e resolver qualquer problema que você possa enfrentar durante o uso do MyHelp.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 px-2">
                            <div className="bg-strong_blue text-white p-4" style={{ borderRadius: 20, width: '100%', height: 440 }}>
                                <div className="d-flex justify-content-center mb-3">
                                    <FaShieldAlt className="fa-5x text-warning" />
                                </div>
                                <h5 className="font-weight-bold" style={{ fontSize: 28 }}>Segurança em primeiro lugar</h5>
                                <p style={{ fontSize: 20 }}>
                                    Sua segurança é nossa prioridade. Implementamos rigorosos protocolos de segurança para proteger suas informações pessoais e garantir transações seguras em nossa plataforma.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 px-2">
                            <div className="bg-strong_blue text-white p-4" style={{ borderRadius: 20, width: '100%', height: 440 }}>
                                <div className="d-flex justify-content-center mb-3">
                                    <FaLock className="fa-5x text-warning" />
                                </div>
                                <h5 className="font-weight-bold" style={{ fontSize: 28 }}>Privacidade respeitada</h5>
                                <p style={{ fontSize: 20 }}>
                                    Respeitamos sua privacidade. Mantemos seus dados seguros e confidenciais, garantindo que suas informações pessoais sejam tratadas com o máximo cuidado e proteção em nossa plataforma.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white py-5" id='funcionalidades'>
                <div className="container" style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <h2 className="text-center text-primary display-4 font-weight-bold mb-4">Nossas funcionalidades</h2>
                    <p className="text-center mb-5" style={{ fontSize: 25, width: '80%', margin: 'auto' }}>
                        Explore nossas funcionalidades exclusivas que tornam a experiência do app MyHelp única e eficiente para você.
                        Desde a contratação de serviços personalizados até a conexão instantânea entre prestadores e clientes,
                        estamos aqui para simplificar sua vida.
                    </p>
                    <div className="column" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <div className="col-md-10 mb-4 m-auto">
                            <div className="d-flex align-items-center">
                                <div className="bg-primary p-2" style={{ border: '4px solid #ff5300', borderRadius: 15, marginRight: 15, width: 350, borderWidth: 15, zIndex: 1, height: 230, overflow: 'hidden' }}>
                                    <img src={ome} alt="Serviços personalizados" className="img-fluid rounded" style={{ width: '100%', height: '100%', objectFit: 'cover', transform: 'scale(1.1)', zIndex: 2, borderRadius: 1 }} />
                                </div>

                                <div className="bg-strong_blue text-white p-3" style={{ flex: 1, marginLeft: -30, zIndex: 0, height: 190, borderRadius: 20 }}>
                                    <h5 className="font-weight-bold" style={{ fontSize: 28, textAlign: 'left', marginLeft: 35, marginTop: 10 }}>Serviços personalizados</h5>
                                    <p style={{ fontSize: 20, textAlign: 'left', marginLeft: 35, width: '80%' }}>Obtenha serviços adaptados às suas necessidades específicas com apenas alguns cliques, garantindo que cada projeto seja tratado com atenção e qualidade.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 mb-4 m-auto">
                            <div className="d-flex align-items-center">
                                <div className="bg-primary p-2" style={{ border: '4px solid #ff5300', borderRadius: 15, marginRight: 15, width: 350, borderWidth: 15, zIndex: 1, height: 230, overflow: 'hidden' }}>
                                    <img src={omefurano} alt="Alugueis de ferramentas" className="img-fluid rounded" style={{ width: '100%', height: '100%', objectFit: 'cover', transform: 'scale(1.1)', zIndex: 2, borderRadius: 1 }} />
                                </div>
                                <div className="bg-strong_blue text-white p-3" style={{ flex: 1, marginLeft: -30, zIndex: 0, height: 190, borderRadius: 20 }}>
                                    <h5 className="font-weight-bold" style={{ fontSize: 28, textAlign: 'left', marginLeft: 35, marginTop: 10 }}>Aluguel de ferramentas</h5>
                                    <p style={{ fontSize: 20, textAlign: 'left', marginLeft: 35, width: '80%' }}>Economize tempo e dinheiro alugando as ferramentas certas para suas tarefas, diretamente pelo MyHelp, proporcionando praticidade e conveniência.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 mb-4 m-auto">
                            <div className="d-flex align-items-center">
                                <div className="bg-primary p-2" style={{ border: '4px solid #ff5300', borderRadius: 15, marginRight: 15, width: 350, borderWidth: 15, zIndex: 1, height: 230, overflow: 'hidden' }}>
                                    <img src={muieCelular} alt="Conexão instantânea" className="img-fluid rounded" style={{ width: '100%', height: '100%', objectFit: 'cover', transform: 'scale(1.1)', zIndex: 2, borderRadius: 1 }} />
                                </div>
                                <div className="bg-strong_blue text-white p-3" style={{ flex: 1, marginLeft: -30, zIndex: 0, height: 190, borderRadius: 20 }}>
                                    <h5 className="font-weight-bold" style={{ fontSize: 28, textAlign: 'left', marginLeft: 35, marginTop: 10 }}>Conexão instantânea</h5>
                                    <p style={{ fontSize: 20, textAlign: 'left', marginLeft: 35, width: '90%' }}>Nossa tecnologia avançada de algoritmos conecta prestadores de serviços e clientes de forma rápida e eficaz, aumentando a visibilidade e as oportunidades para os trabalhadores autônomos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="d-flex" style={{ backgroundColor: '#034aad', padding: '40px 0', height: 490, alignItems: 'center' }}>
                <div className="d-flex flex-column text-white" style={{ backgroundColor: '#ff5300', borderRadius: '0px 100px 100px 0px', padding: '30px', width: '50%', alignSelf: 'flex-start', height: '90%', marginTop: 20 }}>
                    <div style={{ alignSelf: 'flex-end', marginRight: 60 }}>
                        <h2 className="font-weight-bold" style={{ fontSize: 46, textAlign: 'start', marginTop: 30 }}>Serviços em <br /> apenas <span style={{ fontWeight: '900' }}>um clique!</span></h2>
                        <p className="mb-4" style={{ textAlign: 'start', fontSize: 26 }}>Baixe seu app agora mesmo!</p>
                        <div className="d-flex">
                            <img src={android} alt="Google Play" className="mr-2 img-fluid" style={{ height: '55px' }} />
                            <img src={ios} alt="App Store" className="img-fluid" style={{ height: '55px' }} />
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', marginLeft: '50%' }}>
                    <img src={phone} alt="Imagem de uma pessoa sorrindo e apontando" className="img-fluid" style={{ height: 550, marginTop: 150, zIndex: 99999 }} />
                </div>

            </section>



            <section className="py-5 bg-light" id='contato'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 m-auto" style={{ textAlign: 'left' }}>
                            <h2 style={{ fontSize: 58, fontWeight: 'bolder' }} className="text-primary">Fale conosco</h2>
                            <p style={{ fontSize: 24 }}>
                                Estamos ansiosos para ouvir você! Se tiver dúvidas, sugestões, ideias de parceria ou apenas quiser entrar em contato, envie-nos uma mensagem.
                                Nossa equipe está aqui para ajudar e valoriza sua opinião. Queremos tornar o app MyHelp o melhor possível com a sua colaboração.
                            </p>
                        </div>
                        <div className="col-md-4 m-auto">
                            <form style={{ textAlign: 'left', fontSize: 18 }} onSubmit={(e) => e.preventDefault()}>
                                <div className="form-group" >
                                    <label htmlFor="name">Nome:</label>
                                    <input type="text" className="form-control" style={{ height: 50 }} id="name" placeholder="Digite o seu nome" value={message.name} onChange={(e) => setMessage({ ...message, name: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Endereço de e-mail:</label>
                                    <input type="email" className="form-control" style={{ height: 50 }} id="email" placeholder="Digite seu e-mail" value={message.email} onChange={(e) => setMessage({ ...message, email: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">Assunto:</label>
                                    <input type="text" className="form-control" style={{ height: 50 }} id="subject" placeholder="Digite o assunto" value={message.subject} onChange={(e) => setMessage({ ...message, subject: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Mensagem:</label>
                                    <textarea className="form-control" id="message" rows="4" placeholder="Digite sua mensagem" value={message.message} onChange={(e) => setMessage({ ...message, message: e.target.value })}></textarea>
                                </div>
                                <button onClick={() => sendMessage()} className="btn btn-warning font-weight-bold text-white p-3 px-4">Enviar!</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="bg-black text-white py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src={logoFull} alt="MyHelp Logo" className="img-fluid mb-3" />
                            <p>Soluções rápidas, serviços confiáveis</p>
                        </div>
                        <div className="col-md-8 d-flex justify-content-center align-items-center">
                            <div className="mr-5" style={{ textAlign: 'left', fontSize: 22 }}>
                                <h5 className="font-weight-bold">Redes Sociais</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=61560426666987" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/appmyhelp/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Instagram
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div style={{ textAlign: 'left', fontSize: 22 }}>
                                <h5 className="font-weight-bold">Outros contatos</h5>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="https://wa.me/5516997375063" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            WhatsApp
                                        </a>
                                    </li>
                                    <li>Threads</li> {/* Substitua 'Threads' pelo link ou informação correta, se houver */}
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="position-absolute" style={{ right: '10%', bottom: '10%', opacity: 0.1 }}>
                        <img src={simbolo} alt="Background Logo" className="img-fluid" />
                    </div>
                </div>
            </footer>
            <ToastContainer />

        </div>
    );
}

export default Landing;
